var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('header',{staticClass:"kiosk-nav"},[_c('alert'),_c('div',{staticClass:"container-wide"},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"3"}},[_c('img',{staticClass:"kiosk-nav-logo",attrs:{"src":_vm.organisationUrls.logoUrl,"alt":""}})]),_c('b-col',{attrs:{"cols":"6"}},[(_vm.approvedToken != undefined)?_c('h4',{staticClass:"kiosk-title"},[_vm._v(" "+_vm._s(_vm.$t('freeDesksSubtitle'))+" ")]):_vm._e()]),_c('b-col',{attrs:{"cols":"3"}},[(_vm.approvedToken != undefined)?_c('div',{staticClass:"kiosk-buttons"},[(_vm.allAttributes.length)?_c('div',{staticClass:"tar"},[_c('div',{staticClass:"floorplan-values"},[_c('attributes-dropdown',{attrs:{"options":_vm.allAttributes,"selected":_vm.attributeFilters},on:{"update-selection":_vm.updateSelectedFiltersForFloors}})],1)]):_vm._e(),(_vm.floors.length > 1)?_c('floors-dropdown',_vm._b({staticClass:"mr-1",attrs:{"variant":"primary","on-floor-selection":_vm.handleFloorSelection}},'floors-dropdown',{
                floors: _vm.floors,
                currentFloor: _vm.currentFloor
              },false)):_vm._e(),_c('b-button',{staticClass:"button-timepicker mr-1 rounded",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show('modal-changedate')}}},[(_vm.startTime && _vm.endTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.startTime,'timezone', _vm.buildingTimezone, 'HH:mm'))+" - "+_vm._s(_vm._f("moment")(_vm.endTime,'timezone', _vm.buildingTimezone, 'HH:mm'))+" ")]):_vm._e()])],1):_vm._e()])],1)],1)],1),(_vm.isMapAvailable && _vm.approvedToken != undefined)?_c('section',{staticClass:"section-settings"},[_c('div',{staticClass:"container-wide"},[_c('div',{staticClass:"row justify-content-start justify-content-md-center"},[_c('div',{staticClass:"col-12"},[_c('SvgMap',_vm._b({attrs:{"current-rooms":_vm.filteredCurrentRooms},on:{"updateSelectedObject":_vm.updateSelectedObject}},'SvgMap',{
              currentFloor: _vm.currentFloor,
              activeFloorDesks: _vm.activeFloorDesks,
              selectedObject: _vm.selectedObject,
              desks: _vm.desks,
              floors: _vm.floors,
              rooms: _vm.rooms,
              organisation: _vm.organisation,
              updatedObjectId: _vm.updatedObjectId,
              isKiosk: true
            },false))],1)])])]):_c('section',[(_vm.loadingReady)?_c('h1',{staticClass:"no-map-available"},[_vm._v(" "+_vm._s(_vm.$t('mapNotAvailable'))+" ")]):_vm._e(),(_vm.approvedToken == undefined)?_c('div',{staticClass:"container no-map-available"},[_c('hr'),_c('h1',[_vm._v("Screen ID: "+_vm._s(_vm.kioskId))]),_c('h3',[_vm._v(" Map will appear when an administrator approves this screen ID ")]),_c('hr'),_c('h5',{staticClass:"mt-5"},[_vm._v("Powered by okku.io")]),_vm._v(" "+_vm._s(_vm.approvedToken)+" ")]):_vm._e()]),_c('reservation-modal',_vm._b({attrs:{"is-busy":_vm.busy},on:{"reserve":_vm.reserveObject}},'reservation-modal',{
      isKiosk: true,
      selectedObject: _vm.selectedObject,
      startTime: _vm.startTime,
      endTime: _vm.endTime,
      showReservationSubject: _vm.showReservationSubject
    },false)),_c('reservation-timepicker-modal',_vm._b({attrs:{"raw-timeslots":_vm.buildingTimeslots,"hide-date":"","show-hints":""}},'reservation-timepicker-modal',{ reservationSettings: _vm.reservationSettings, navigated: _vm.navigated, navigatedDate: _vm.navigatedDate },false)),_c('todays-availability-modal',_vm._b({},'todays-availability-modal',{
      isKiosk: true,
      selectedObject: _vm.selectedObject,
      startTime: _vm.startTime,
      endTime: _vm.endTime,
      buildingTimezone: _vm.buildingTimezone,
      buildingTimeslots: _vm.buildingTimeslots
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }