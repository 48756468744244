<template>
  <div class="page-container">
    <header class="kiosk-nav">
      <alert />
      <div class="container-wide">
        <b-row align-v="center">
          <b-col cols="3"
            ><img
              :src="organisationUrls.logoUrl"
              class="kiosk-nav-logo"
              alt
          /></b-col>
          <b-col cols="6">
            <h4 v-if="approvedToken != undefined" class="kiosk-title">
              {{ $t('freeDesksSubtitle') }}
            </h4>
          </b-col>
          <b-col cols="3">
            <div
              v-if="approvedToken != undefined"
              class="kiosk-buttons"
            >
              <div v-if="allAttributes.length" class="tar">
                <div class="floorplan-values">
                  <attributes-dropdown
                    :options="allAttributes"
                    :selected="attributeFilters"
                    @update-selection="updateSelectedFiltersForFloors"
                  />
                </div>
              </div>

              <floors-dropdown
                v-if="floors.length > 1"
                class="mr-1"
                variant="primary"
                v-bind="{
                  floors,
                  currentFloor
                }"
                :on-floor-selection="handleFloorSelection"
              />

              <b-button
                class="button-timepicker mr-1 rounded"
                variant="primary"
                @click="$bvModal.show('modal-changedate')"
              >
                <span v-if="startTime && endTime">
                  {{
                    startTime
                      | moment('timezone', buildingTimezone, 'HH:mm')
                  }}
                  -
                  {{
                    endTime
                      | moment('timezone', buildingTimezone, 'HH:mm')
                  }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>
    <section
      v-if="isMapAvailable && approvedToken != undefined"
      class="section-settings"
    >
      <div class="container-wide">
        <div
          class="row justify-content-start justify-content-md-center"
        >
          <div class="col-12">
            <SvgMap
              v-bind="{
                currentFloor,
                activeFloorDesks,
                selectedObject,
                desks,
                floors,
                rooms,
                organisation,
                updatedObjectId,
                isKiosk: true
              }"
              :current-rooms="filteredCurrentRooms"
              @updateSelectedObject="updateSelectedObject"
            />
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <h1 v-if="loadingReady" class="no-map-available">
        {{ $t('mapNotAvailable') }}
      </h1>
      <div
        v-if="approvedToken == undefined"
        class="container no-map-available"
      >
        <hr />
        <h1>Screen ID: {{ kioskId }}</h1>
        <h3>
          Map will appear when an administrator approves this screen
          ID
        </h3>
        <hr />
        <h5 class="mt-5">Powered by okku.io</h5>
        {{ approvedToken }}
      </div>
    </section>
    <reservation-modal
      v-bind="{
        isKiosk: true,
        selectedObject,
        startTime,
        endTime,
        showReservationSubject
      }"
      :is-busy="busy"
      @reserve="reserveObject"
    />
    <reservation-timepicker-modal
      v-bind="{ reservationSettings, navigated, navigatedDate }"
      :raw-timeslots="buildingTimeslots"
      hide-date
      show-hints
    />
    <todays-availability-modal
      v-bind="{
        isKiosk: true,
        selectedObject,
        startTime,
        endTime,
        buildingTimezone,
        buildingTimeslots
      }"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

import columnSortable from 'vue-column-sortable'
import vueMoment from 'vue-moment'
import _each from 'lodash/each'
import moment from 'moment-timezone'

import Alert from '@/components/common/Alert'
import FloorsDropdown from '@/components/common/FloorsDropdown'

import ReservationTimepickerModal from '@/components/modals/ReservationTimepickerModal'
import SvgMap from '@/components/common/SvgMap'
import ReservationModal from '@/components/modals/ReservationModal'
import TodaysAvailabilityModal from '@/components/modals/TodaysAvailabilityModal'

// import ReservationMixin from '@/mixins/ReservationsMixin'
import { filterTableMixin } from '@/mixins/mixins'
import AttributesMixin from '@/mixins/AttributesMixin'
import OkkuApi from '@/services/OkkuApi'
import axios from 'axios'
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode'

import {
  SET_ALERT,
  SET_TIMEPICKER_STATE
} from '@/store/modules/common/mutationTypes'

import { OCCUPANCY, OBJECTS_TYPES } from '@/constants'

import runWithTimeout from '@/utils/runWithTimeout'
import createRandomToken from '@/utils/createRandomToken'

Vue.use(vueMoment)

export default {
  name: 'KioskReservationsPage',
  components: {
    Alert,
    SvgMap,
    FloorsDropdown,

    ReservationTimepickerModal,
    ReservationModal,
    TodaysAvailabilityModal,
    AttributesDropdown: () =>
      import('@/components/common/AttributesDropdown.vue')
  },
  directives: {
    columnSortable
  },
  // Include attributes mixin for easily handling of attributes
  mixins: [filterTableMixin, AttributesMixin /*, ReservationMixin */],
  metaInfo: {
    meta: [
      {
        vmid: 'viewport',
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
      }
    ]
  },
  data() {
    return {
      approvedToken: undefined,
      kioskId: '',
      sortKey: 'status',
      currentValue: 'desks',
      maxReservations: null,
      buildingTimeslots: [],
      startTime: '',
      endTime: '',
      availableDesks: [],
      currentFloor: {},
      selectedObject: {},
      currentRoom: [],
      currentRooms: [],
      roomStatuses: [],
      connection: '',
      isMapAvailable: false,
      busy: false,
      OCCUPANCY,
      attributeFilters: [],
      updatedObjectId: '',
      navigated: false,
      navigatedDate: '',
      stopTimeout: false, // Flag to control the token approval check
      kioskFloors: [],
      org: '',
      orgPath: ''
    }
  },

  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      // organisationUrls: ,
      // reservationSettings: 'organisationReservationSettings',
      timepicker: 'timepicker',
      loadingReady: 'ready'
    }),
    // ...mapState('common', ['floors', 'desks', 'rooms', 'timepicker']),
    ...mapState('kiosk', [
      'floors',
      'desks',
      'rooms',
      'organisationUrls'
    ]),

    // Unable to get the mapGetters work, so reverting to referencing the state
    // ...mapGetters('kiosk', {
    //   buildingTimezone: 'buildingTimezone', allowDeskReservationSubject: 'kiosk?.$settings?.allowRoomReservationSubject',
    //   allowRoomReservationSubject: 'allowRoomReservationSubject'
    // }),

    // ...mapGetters('common', {
    //   buildingTimezone: 'buildingTimezone',
    //   // this should always be true: task condition
    //   roomReservationSubject: 'allowRoomReservationSubject',
    //   deskReservationSubject: 'allowDeskReservationSubject',
    //   userDetail: 'userDetail',
    //   reservationsettings: 'reservationsettings'
    // }),
    floors() {
      return this.$store.state?.kiosk?.floors
    },
    reservationSettings() {
      return this.$store.state?.kiosk?.$settings?.reservationSettings
    },
    buildingTimezone() {
      return (
        this.$store.state?.kiosk?.$settings?.reservationSettings
          ?.timezone || 'undefined'
      )
    },
    activeFloorDesks() {
      return this.availableDesks
        ? this.availableDesks.filter(desk => {
            const isDeskAvailableOnFloor =
              this.currentFloor &&
              this.currentFloor.id === desk.floorId
            return this.filterValuesByAttributes(
              isDeskAvailableOnFloor,
              desk
            )
          })
        : []
    },
    filteredCurrentRooms() {
      return this.currentRooms.filter(room => {
        const isRoomAvailableOnFloor =
          this.currentFloor && this.currentFloor.id === room.floorId
        return this.filterValuesByAttributes(
          isRoomAvailableOnFloor,
          room
        )
      })
    },
    reservedRoomIds() {
      return this.filteredCurrentRooms
        .filter(room => room.status === OCCUPANCY.RESERVED)
        .map(room => room.id)
    },
    showReservationSubject() {
      if (
        this.selectedObject.type === 'DESK' &&
        this.$store.state.kiosk?.$settings
          ?.allowDeskReservationSubject
      ) {
        return true
      }
      if (
        this.selectedObject.type === 'ROOM' &&
        this.$store.state.kiosk?.$settings
          ?.allowRoomReservationSubject
      ) {
        return true
      }
      return false
    }
  },
  watch: {
    currentValue() {
      this.resetAttributeFilter()
    },
    $lastUpdate() {
      this.getDeskStatus()
    },

    floors() {
      if (this.floors.length > 0) {
        const [currentFloor] = this.floors
        this.currentFloor = currentFloor
        this.isMapAvailable = true
      }
    },
    currentFloor() {
      this.getCurrentFloorRooms()
    },
    desks(newDesks, oldDesks) {
      // fetch desk status when desks data changes e.g. edited from SvgEditor
      if (oldDesks && oldDesks.length) {
        this.getDeskStatus()
      }
    },
    timepicker(nextState, prevState) {
      if (
        prevState.date !== null &&
        nextState.date !== prevState.date
      ) {
        this.getBuildingTimeslots(nextState.date)
        return
      }
      if (
        this.startTime !== nextState.startTime ||
        this.endTime !== nextState.endTime
      ) {
        this.startTime = nextState.startTime
        this.endTime = nextState.endTime
        this.checkAvailability()
      }
    }
  },

  created() {
    this.parseDataFromRoute()
    // todo: later
    // this.getBuildingThemeSettings()
  },
  destroyed() {
    if (this.connection) {
      this.connection.close()
    }
  },
  async mounted() {
    // Check for approved token in local storage
    const interval = 10000 // 10 seconds
    runWithTimeout({
      condition: () => this.executeTokenRetrieval(),
      callback: () => {},
      maxAttempts: 3600,
      interval
    })
  },
  methods: {
    ...mapActions('kiosk', ['loadKiosk']),

    parseDataFromRoute() {
      this.org = this.$route.params.org
      this.building = this.$route.params.building
      this.orgPath = this.$route.params.building
      this.kioskFloors = this.$route.query.floors
    },
    async executeTokenRetrieval() {
      if (
        !(await this.hasApprovedToken(
          this.org,
          this.building,
          this.kioskFloors
        ))
      ) {
        return false
      }
      await this.$store.dispatch(
        'common/updateOrganization',
        `/${this.approvedToken.org}/${this.approvedToken.building}`
      )

      // this is only called once a valid token is received from the server
      await this.loadBuildingData()

      // make sure we reload changed opening hours etc every 3 hours
      setInterval(() => this.loadBuildingData(), 1000 * 3600 * 3)

      // refresh the JWT before it expires
      await this.setTokenRefresh(
        this.org,
        this.building,
        this.kioskFloors,
        this.kioskId
      )

      return true
    },

    async loadBuildingData() {
      console.log('Loading building data')
      axios.defaults.headers.common.Authorization = `Bearer ${this.approvedToken.token}`

      // Get all attributes through the Attribute Mixin using the API
      await this.getAllAttributes()
      await this.loadKiosk(this.organisation)
      // If floors array is filled, select the current and show the map
      if (this.floors.length > 0) {
        this.isMapAvailable = true
        const [currentFloor] = this.floors
        const currentBuilding = localStorage.getItem('BUILDING')
        const lastSelectedFloor = JSON.parse(
          localStorage.getItem('floor') || null
        )
        if (
          lastSelectedFloor &&
          currentBuilding === lastSelectedFloor.building
        ) {
          this.currentFloor = lastSelectedFloor
        } else {
          this.currentFloor = currentFloor
        }
      }

      if (this.organisation) {
        this.subscribeToUpdates(this.organisation)
        this.getBuildingTimeslots(this.timepicker.date || undefined)
      }

      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        this.selectedObject = {}
      })

      if (Object.values(this.timepicker).every(Boolean)) {
        this.startTime = this.timepicker.startTime
        this.endTime = this.timepicker.endTime
        this.checkAvailability()
      }

      // If floors array is filled, select the current and show the map
      if (this.floors.length > 0) {
        this.isMapAvailable = true
        const [currentFloor] = this.floors
        const currentBuilding = localStorage.getItem('BUILDING')
        const lastSelectedFloor = JSON.parse(
          localStorage.getItem('floor') || null
        )
        if (
          lastSelectedFloor &&
          currentBuilding === lastSelectedFloor.building
        ) {
          this.currentFloor = lastSelectedFloor
        } else {
          this.currentFloor = currentFloor
        }
      }

      // allow timepicker to enforce its rules
      if (
        this.timepicker.startTime != null &&
        this.timepicker.endTime != null
      ) {
        this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
          startTime: this.timepicker.startTime,
          endTime: this.timepicker.endTime,
          date: this.timepicker.date
        })
      }
    },

    filterValuesByAttributes(isAvailable = false, currentData) {
      if (this.attributeFilters && this.attributeFilters.length) {
        if (
          isAvailable &&
          currentData.attributes &&
          currentData.attributes.length
        ) {
          return this.attributeFilters.every(
            id =>
              currentData.attributes.findIndex(
                attribute => attribute.id === id
              ) !== -1
          )
        }
        return false
      }
      return isAvailable
    },
    resetAttributeFilter() {
      this.attributeFilters = []
    },
    updateSelectedFiltersForFloors(val) {
      this.attributeFilters = val
    },
    handleFloorSelection(floor) {
      const building = localStorage.getItem('BUILDING')
      localStorage.setItem(
        'floor',
        JSON.stringify({ ...floor, building })
      )
      this.currentFloor = floor
      this.checkAvailability()
      this.selectedObject = {}

      const el = document.getElementsByClassName('active img-desk')
      if (el && el.length) {
        el[0].remove()
      }
    },
    getReservationLabel(desk) {
      if (!desk.reservable) {
        return this.$t('canNotBeReservedThisTime')
      }
      if (desk.status === OCCUPANCY.RESERVED) {
        return `${this.$t('reserved')} ${this.$t('until')} ${moment(
          desk.reservationEndDatetime
        )
          .tz(this.buildingTimezone)
          .format('HH:mm')}`
      }
      if (desk.status === OCCUPANCY.OCCUPIED) {
        return `${this.$t('occupied')} / ${this.$t('reserved')}`
      }
      return ''
    },
    orderBy(fn) {
      this.availableDesks.sort(fn)
    },
    updateSelectedObject(selectedObject) {
      this.selectedObject = selectedObject
    },
    checkAvailability() {
      this.getDeskStatus()
      this.getRoomStatus().then(() => {
        this.getCurrentFloorRooms()
      })
    },
    subscribeToUpdates(path) {
      if (this._isDestroyed) {
        return
      }
      const wsUrl =
        process.env.VUE_APP_OKKU_API_WEBSOCKET_CONNECTION ||
        `wss://${window.location.hostname}:${window.location.port}`
      console.log(`Subscribing to ${wsUrl} @ ${path}`)
      this.connection = new WebSocket(wsUrl)
      const { connection } = this
      connection.onopen = () => {
        console.log('connected')
        setTimeout(() => {
          connection.send(`SUB:${path}`)
          console.log(`Subscribed to ${path}`)
        }, 100)
      }
      connection.onmessage = event => {
        console.log('received', event.data)
        this.applyWebsocketUpdate(event.data)
      }
      connection.onclose = () => {
        console.log('websocket closed')
        this.subscribeToUpdates(this.organisation)
      }
      connection.onerror = error => {
        console.log('failed to connect', error)
      }
    },
    getRoomStatus() {
      const query = `?from=${this.startTime}&to=${this.endTime}&sensorGap=15`
      return OkkuApi.getRoomStatusForKiosk(query).then(
        roomStatuses => {
          this.roomStatuses = roomStatuses
        }
      )
    },
    updateDeskById({ id, status, firstName, lastName, userId }) {
      const desks = [...this.availableDesks]

      desks.forEach(activeDesk => {
        if (activeDesk.id === id) {
          if (!activeDesk.context) {
            activeDesk.context = {
              firstName: '',
              lastName: ''
            }
          }
          activeDesk.status = status
          activeDesk.context.firstName = firstName
          activeDesk.context.lastName = lastName
          activeDesk.context.reservedBy = null
          activeDesk.userId = userId
        }
      })

      this.availableDesks = desks
    },
    applyWebsocketUpdate(update) {
      const updateParts = update.split('/')
      const updatePath = `/${updateParts[1]}/${updateParts[2]}`
      const actionName = `${updateParts[3]}`

      // apply fixed desks
      if (
        this.organisation === updatePath &&
        actionName === 'FIX-DESKS'
      ) {
        const fixedDesks = JSON.parse(updateParts[4])

        fixedDesks.forEach(fixedDesk => {
          this.updateDeskById(fixedDesk)
        })
      }

      // check for org/building against organisation
      if (
        this.organisation === updatePath &&
        actionName !== 'FIX-DESKS'
      ) {
        const objectId = updateParts[3]
        const action = updateParts[4]
        const startDatetime = moment(updateParts[5])
        const endDatetime = moment(updateParts[6])
        // is the update relevant to the selected interval?
        if (
          startDatetime.isBefore(moment(this.endTime)) &&
          endDatetime.isAfter(this.startTime)
        ) {
          let desk
          // Intervals match
          if (this.availableDesks) {
            desk = this.availableDesks.filter(
              availableDesk => availableDesk.id === objectId
            )
            if (desk && desk.length) {
              ;[desk] = desk
            } else {
              desk = null
            }
          }
          if (desk) {
            if (action === 'CREATE') {
              desk.status = OCCUPANCY.RESERVED
              desk.reservationStartDatetime = startDatetime
              desk.reservationEndDatetime = endDatetime
              desk.showUserDetails = false
            }
            if (action === 'CANCEL') {
              desk.status = OCCUPANCY.FREE
              desk.reservationStartDatetime = null
              desk.reservationEndDatetime = null
              delete desk.reservedForUser
              delete desk.reservedFor
            }
          } else {
            const room = this.currentRooms.find(
              r => r.id === objectId
            )
            if (room) {
              if (action === 'CREATE') {
                room.status = OCCUPANCY.RESERVED
                room.reservationStartDatetime = startDatetime
                room.reservationEndDatetime = endDatetime
                room.showUserDetails = false
                this.updatedObjectId = room.id // hack to update the SVG map
              }
              if (action === 'CANCEL') {
                room.status = OCCUPANCY.FREE
                room.reservationStartDatetime = null
                room.reservationEndDatetime = null
                delete room.reservedForUser
                delete room.reservedFor
                this.updatedObjectId = room.id // hack to update the SVG map
              }
            }
          }
        }
      }
    },

    getDeskStatus() {
      //      console.log(`this.floors = ${JSON.stringify(this.floors)}`)
      //      console.log(`this.currentFloor = ${JSON.stringify(this.currentFloor)}`)
      const query = `?from=${this.startTime}&to=${this.endTime}&floorId=${this.currentFloor.id}`
      return OkkuApi.getDeskStatusForKiosk(query).then(data => {
        const availableDesks = data.map(deskStatus => {
          // eslint-disable-next-line no-restricted-syntax
          for (const deskDef of this.desks) {
            if (deskDef.id === deskStatus.id) {
              deskStatus.svgSnippet = deskDef.svgSnippet
              deskStatus.attributes = deskDef.attributes
              break
            }
          }
          return deskStatus
        })
        // this.availableDesks = availableDesks
        this.availableDesks = availableDesks

        const deepLinkId = this.$route.params.objectId
        if (deepLinkId) {
          // deep link to reserve a desk
          _each(this.desks, desk => {
            if (desk.id === deepLinkId) {
              this.selectedObject = desk
            }
          })
          this.$bvModal.show('modal-reserve')
          this.$route.params.objectId = undefined // ensure the deep link is not carried out after every query
        }
      })
    },
    getCurrentFloorRooms() {
      this.currentRooms = this.rooms.filter(
        room => room.floorId === this.currentFloor.id
      )
      // map with status
      _each(this.currentRooms, (room, index) => {
        _each(this.roomStatuses, roomStatus => {
          if (roomStatus.id === room.id) {
            this.currentRooms[index] = {
              ...room,
              ...roomStatus
            }
          }
        })
      })
    },
    constructQueryParams({
      repeatWeeks,
      visibleToOthers,
      reservationSubject,
      reservationAssets
    }) {
      let query = `?objectId=${this.selectedObject.id}&from=${this.startTime}&to=${this.endTime}&visibleToOthers=${visibleToOthers}`
      if (repeatWeeks) {
        query += `&repeatWeeks=${repeatWeeks}`
      }
      if (this.selectedObject.roomEmail !== 'null') {
        query += `&roomEmail=${this.selectedObject.roomEmail}`
      }
      if (reservationSubject) {
        query += `&reservationSubject=${reservationSubject}`
      }

      Object.values(reservationAssets).forEach(asset => {
        if (asset.checked) {
          query += `&${asset.name}`
        }
      })
      return query
    },
    reserveObject(data) {
      if (this.busy) return

      this.busy = true
      const { invitedUsers, reservationAssets } = data
      const query = this.constructQueryParams(data)

      OkkuApi.reserveObjectForKiosk(
        query,
        invitedUsers,
        reservationAssets
      )
        .then(async response => {
          let desk
          this.busy = false
          const message = this.$t('kioskReservationConfirmation', {
            objectName: this.selectedObject.name
          })
          const variant = 'success'

          // additional alert message
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 10,
            text: message,
            variant
          })
          // apply the reservation locally
          if (this.availableDesks) {
            desk = this.availableDesks.filter(
              availableDesk =>
                availableDesk.id === this.selectedObject.id
            )
            if (desk && desk.length) {
              ;[desk] = desk
            } else {
              desk = null
            }
          }

          if (desk) {
            desk.status = OCCUPANCY.RESERVED
            desk.reservationStartDatetime = this.startTime
            desk.reservationEndDatetime = this.endTime
            // desk.reservedForUser = email
            desk.context = {
              reservedBy: { firstName: 'Kiosk', lastName: 'User' }
            }
            desk.showUserDetails = true
            desk.visibleToOthers = true
            desk.reservationSubject = data?.reservationSubject || null
          } else {
            const room = this.currentRooms.find(
              r => r.id === this.selectedObject.id
            )
            if (invitedUsers && invitedUsers.length)
              room.isGuestInvited = true
            room.status = OCCUPANCY.RESERVED
            room.reservationStartDatetime = this.startTime
            room.reservationEndDatetime = this.endTime
            // room.reservedForUser = email
            room.context = {
              reservedBy: { firstName: 'Kiosk', lastName: 'User' }
            }

            room.showUserDetails = true
            desk.visibleToOthers = true
            room.reservationSubject = data?.reservationSubject || null
          }

          // Apply room reservation status locally
          this.updateRoomStatus(data)
        })
        .catch(({ response: { data: responseData } }) => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: responseData.message,
            variant: 'danger'
          })
          this.busy = false
        })
        .finally(() => {
          this.$bvModal.hide('modal-reserve')
        })
    },
    updateRoomStatus(data) {
      const oldRooms = [...this.currentRooms]
      oldRooms.forEach(room => {
        if (
          room.type === OBJECTS_TYPES.ROOM &&
          room.id === this.selectedObject.id
        ) {
          room.status = OCCUPANCY.RESERVED
          room.reservationSubject = data.reservationSubject || null
          room.visibleToOthers = data?.visibleToOthers || null
        }
      })
      this.currentRooms = oldRooms
    },
    getBuildingTimeslots(date) {
      OkkuApi.getTimeslotsForKiosk(
        this.organisation.slice(1),
        date
      ).then(timeslots => {
        if (timeslots && timeslots.length) {
          this.buildingTimeslots = timeslots
        } else {
          this.buildingTimeslots = []
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('noTimeSlotsOnThisDay'),
            variant: 'warning'
          })
        }
      })
    },
    async hasApprovedToken(org, building, floors) {
      const approvedToken = localStorage.getItem('approvedToken')
      if (approvedToken) {
        console.log('Getting token from localStorage')
        this.approvedToken = JSON.parse(approvedToken)
        return true
      }
      console.log('No token, Getting kioskId')
      const tokenResponse = await this.requestToken(
        org,
        building,
        floors
      )
      return tokenResponse
    },
    async requestToken(org, building, floors) {
      // go to API with kioskId
      const kioskId = await this.getKioskId()
      const response = await OkkuApi.getKioskToken({
        org,
        building,
        floors,
        kioskId
      })
      if (response.status === 202) {
        console.info('kioskId approval pending')
        // set timer to this method after 20 seconds
      }
      if (
        response.status === 200 &&
        response.data?.token !== undefined
      ) {
        console.info('kioskId approved by server')
        const approvedToken = JSON.stringify({
          org: this.org,
          building: this.building,
          token: response.data.token
        })
        // save jwt in local storage
        localStorage.setItem('approvedToken', approvedToken)
        this.approvedToken = JSON.parse(approvedToken)
        return true
      }
      if (response.status === 403) {
        console.warn('kioskId rejected by server')
        this.wipeToken()
      }
      return false
    },
    wipeToken() {
      this.approvedToken = undefined
    },
    async refreshToken(org, building, kioskFloors) {
      console.log(`Refreshing token`)
      this.wipeToken()
      await this.requestToken(org, building, kioskFloors)
      await this.setTokenRefresh(org, building, kioskFloors)
    },
    async getKioskId() {
      if (this.kioskId) return this.kioskId
      let kioskId = localStorage.getItem('kioskId')
      if (kioskId) {
        this.kioskId = kioskId
      } else {
        kioskId = createRandomToken()
        this.kioskId = kioskId
        localStorage.setItem('kioskId', kioskId)
      }
      return kioskId
    },
    async setTokenRefresh(org, building, kioskFloors) {
      const decodedToken = jwt_decode(this.approvedToken.token)
      const margin = 1 // 1 hour margin for token expiration
      const interval = Math.round(
        (decodedToken.exp - Date.now() / 1000 - margin * 3) * 1000
      )
      console.log(
        `Token will refresh after ${interval} milliseconds, exp = ${decodedToken.exp}`
      )
      setTimeout(
        () => this.refreshToken(org, building, kioskFloors),
        interval
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/globals/vars.scss';
@import '../assets/scss/globals/mixins.scss';
@import '../assets/scss/globals/extend.scss';

.kiosk-nav {
  padding-top: 40px;

  .kiosk-nav-logo {
    max-width: 100%;
  }
}

.kiosk-title {
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;

  @include r(767) {
    margin-bottom: 20px;
  }
}

.floorplan-values {
  font-size: 0;
  text-align: right;

  @include r(767) {
    margin-right: 0;
    width: 100%;
    margin-top: 3px;
    text-align: left;
  }

  &--item {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin: 3px;
    background-color: rgba(#fff, 1);
    transition: all $time;
    min-width: 70px;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    padding: 11px;
    border-radius: 4px;
    color: $textcolor-med;
    border: 1px solid rgba($basecolor, 0.2);

    @include r(767) {
      /*margin-left: 0;*/
      /*margin-right: 6px;*/
    }

    &.current,
    &:hover {
      background-color: $basecolor;
      color: #fff;
    }
  }
}

.topbar {
  align-content: center;
  justify-content: space-between;
  display: flex;
  // flex-flow: row wrap;
  margin: 0;

  @include r(767) {
    flex-direction: column;

    & > * {
      margin-top: 1rem;
    }

    & > *:nth-child(2) {
      margin-top: 0;
    }
  }
}

@include rm(991) {
  .nav-pills {
    white-space: nowrap;
  }
}

.btn-calendar {
  @include r(500) {
    display: none !important;
  }
}

.filter-input {
  width: 180px;
}

@include r(575) {
  justify-content: space-between !important;

  .filter-input {
    width: 100% !important;
    margin-top: 4px;
  }
}

@include r(500) {
  .form-control {
    width: 75% !important;
  }
}

.floorplan-values {
  display: inline-block;
  vertical-align: middle;
}

.no-map-available {
  text-align: center;
  padding: 2rem;
}

.floorAndEditButton {
  margin-left: auto !important;
}

.texthide {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kiosk-buttons {
  display: flex;
  flex-direction: row-reverse;

  @include r(767) {
    align-content: center;
    justify-content: space-between;
  }

  .button-timepicker {
    height: 41px;
    min-width: 140px;
    white-space: nowrap;

    svg {
      margin-left: 0.5rem;
      margin-bottom: 0.1rem;
    }
  }
}

@media (max-width: 767px) {
  .navigator-button {
    width: auto !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .calendar-icon {
    display: none;
  }
}
</style>
